import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useState } from "react";
import { refundPayementToUser, releaseHoldPayment } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import Swal from "sweetalert2";
import Modals from 'react-modal';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

var COMMON_STYLE_FOR_POPUP = {
    content: {
        background: 'none',
        border: '0px',
    },
}

export default function RefundRequestDetails({ isLoader }) {

    const navigate = useNavigate()
    const location = useLocation()
    const data = location.state;
    console.log('location :', data);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [amount, setAmount] = useState(data.amount)

    const handleBack = () => {
        navigate(-1);
    };

    const schema = yup.object().shape({
        amount: yup.string().required("Amount is required "),
    });

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (values) => {
        isLoader(true)
        refundPayementToUser({ user_id: data?.user_id, amount: values.amount, payment_intent_id: data?.transaction_id, refund_request_accept: "Accepted", activity_id: data?.activity_id, activity_type: data?.activity_type, actvity_length: data?.length, slot_id: data?.slot_id, enrolled_id: data?.enrolled_id, meeting_id: data.meeting_id })
            .then((response) => {
                if (response.code === "1") {
                    TOAST_SUCCESS(response.message);
                    setIsOpen(false);
                    isLoader(false)
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false)
                }
            })
    }

    const handleReleasePayment = (teacher_id, amount) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to release payment ?",
            showCancelButton: true,
            confirmButtonText: "Okay",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    isLoader(true)
                    releaseHoldPayment({
                        provider_id: teacher_id,
                        amount: data?.total_amount,
                        activity_length : data?.length,
                        for_all_enrolled_id : data?.enrolled_id,
                        enrollment_id: (data?.length == "Ongoing Weekly" || data?.length == "Ongoing Monthly")
                            ? data?.user_enrolled_id
                            : data?.enrolled_id
                    })
                        .then((response) => {
                            if (response.code === "1") {
                                TOAST_SUCCESS(response.message);
                                isLoader(false)
                            } else {
                                TOAST_ERROR(response.message);
                                isLoader(false)
                            }
                        })
                } catch (error) {
                    TOAST_ERROR("Error in release payment");
                    isLoader(false)
                }
            }
        })
    };

    const handleCloseModal = () => {
        // Reset form state and update data.amount
        reset({ amount: data.amount }); // Reset form fields
        setAmount(data.amount); // Update amount in state
        setIsOpen(false); // Close the modal
    }

    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>

            </div>
        </header>

        <section className="main_sec">
            <div className="mx-5 mt-4">
                <div>
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>

                        <span className="ms-2">Back</span>
                    </span>
                </div>
                <p className="ms-3 fs-4 fw-500 text-center mb-5">Refund Request Details</p>

                <div className="row d-flex justify-content-center align-items-center mb-5">
                    <div className="col col-lg-6 mb-4 mb-lg-0">
                        <div className="card mb-3 p-3" style={{ borderRadius: ".5rem" }}>
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="card-body p-4">
                                        <h6 className="mb-2">Information</h6>
                                        <hr className="mt-0 mb-4" />
                                        {data && (
                                            <div className="row pt-1">
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Refund Request From</p>
                                                    <p className="text-muted mt-2">{data.user_name}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Refund Request To</p>
                                                    <p className="text-muted mt-2">{data.teacher_name}</p>
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <p className="fw-600">Reason</p>
                                                    <p className="text-muted mt-2">{data.reason_id}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">  Amount Added By Teacher</p>
                                                    <p className="text-muted mt-2">${data.amount}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600"> Actual Price of Class</p>
                                                    <p className="text-muted mt-2">${data.total_amount}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Transaction Id</p>
                                                    <p className="text-muted mt-2">{data.transaction_id}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Program Name</p>
                                                    <p className="text-muted mt-2">{data.activity_title}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Program Type</p>
                                                    <p className="text-muted mt-2">{data.activity_type}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Platform Type</p>
                                                    <p className="text-muted mt-2">{data.type ? data.type : "Online"}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Duration</p>
                                                    <p className="text-muted mt-2">{data.duration}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Payment Status</p>
                                                    <p className="text-muted mt-2">{data.payment_status}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Request Status</p>
                                                    <p className="text-muted mt-2">{data.refund_request_status}</p>
                                                </div>

                                                {(data.refund_request_status === "Rejected" && data.is_released != "1") && (
                                                    <div className="d-flex justify-content-center mt-4">
                                                        <button
                                                            className="btn-theme bg-yellow fw-500 mx-2"
                                                            onClick={() => handleReleasePayment(data.teacher_id, data.amount)}
                                                        >
                                                            Release Payment
                                                        </button>
                                                        <button
                                                            className="btn-theme fw-500 mx-2"
                                                            onClick={() => setIsOpen(true)}
                                                            disabled={data.payment_status === "refunded"}
                                                        >
                                                            {data.payment_status === "refunded" ? "Refunded" : "Refund Payment"}
                                                        </button>

                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-bottom-0 pb-0">
                        <h5 className="modal-title" id="exampleModalLabel" />
                        <button type="button" className="btn-close" onClick={handleCloseModal} />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                            <div className="form-group mb-4">
                                <label className="mb-2">Refund Amount</label>
                                <div className="position-relative class_title">
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...register("amount")}
                                        defaultValue={data.amount}
                                        name="amount"
                                        maxLength={50}
                                        rows={2}
                                    />
                                    <p className='mt-2 font-bold col-red'>
                                        <span style={{ color: 'red' }}>{errors.amount?.message}</span>
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="d-xl-flex align-items-center">
                                    <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">
                                        Send Refund
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modals>
    </>
}



