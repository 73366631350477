import React, { useState, useEffect } from 'react';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import Hdashboard from '../../../components/Hdashboard';
import { AllTransactions, PaymentMadetoTeachers, RefundsListing, invoice_pdf, paymentReceived, refferalCredits } from '../../../api/apiHandler';
import 'jspdf-autotable';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { CButton, CFormInput, CInputGroup } from '@coreui/react';
import FontAwesome from 'react-fontawesome';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { el } from 'date-fns/locale';
import customStyles from '../../../utils/dataTableStyles';

export default function EWallet({isLoader}) {

    const [data, setPaymentMadeData] = useState([]);
    console.log('data :', data);
    const [refundData, setRefundData] = useState([]);
    const [paymentReceivedData, setPaymentReceivedData] = useState([]);
    const [creditsData, setCreditsData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [search, setSearch] = useState('');
    const [selectedFilterOption, setSelectedFilterOption] = useState({
        value: "all",
        label: "All Transactions"
    });
    const navigate = useNavigate();

    const currentDate = new Date();
    const defaultStartDate = currentDate.toISOString().split('T')[0];
    const defaultStartMonth = currentDate.toISOString().slice(0, 7);
    let initialStartDate = defaultStartDate;
    let initialEndDate = defaultStartDate;

    if (selectedPeriod === 'daily') {
        initialStartDate = currentDate.toISOString().split('T')[0];
        initialEndDate = currentDate.toISOString().split('T')[0];
    } else if (selectedPeriod === 'weekly') {
        const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        initialStartDate = sevenDaysAgo.toISOString().split('T')[0];
        initialEndDate = currentDate.toISOString().split('T')[0];
    }

    // Set the state variables with the initial values
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [startMonth, setStartMonth] = useState(defaultStartMonth);
    const [endMonth, setEndMonth] = useState(defaultStartMonth);
    const currentYear = new Date().getFullYear();
    const [startYear, setStartYear] = useState(currentYear);
    const [endYear, setEndYear] = useState(currentYear);
    
    useEffect(() => {
        if (selectedFilterOption) {
            fetchEwalletData(selectedFilterOption.value);
        }
    }, [selectedPeriod, startDate, endDate, startMonth, endMonth, startYear, endYear, selectedFilterOption, search]);

    const fetchEwalletData = (selectedFilter) => {
        let requestData = {};

        if (selectedPeriod === 'daily') {
            requestData = {
                type: 'daily',
                startDate: startDate,
                endDate: endDate
            };
        } else if (selectedPeriod === 'weekly') {
            requestData = {
                type: 'weekly',
                startDate: startDate,
                endDate: endDate
            };
        } else if (selectedPeriod === 'monthly') {
            requestData = {
                type: 'monthly',
                startDate: startMonth,
                endDate: endMonth
            };
        } else if (selectedPeriod === 'yearly') {
            requestData = {
                type: 'yearly',
                startYear: startYear,
                endYear: endYear
            };
        }

        const requestDataWithSearch = { ...requestData, search: search };

        if (selectedFilter === 'all') {
            isLoader(true)
            AllTransactions(requestDataWithSearch)
                .then((response) => {
                console.log('AllTransactions :', response);
                    if (response.code === "1") {
                        const dataWithRowCount = response.data.map((row, index) => ({ ...row, id: index + 1 }));
                        setAllData(dataWithRowCount);
                        isLoader(false)
                    } else {
                        setAllData([]);
                        isLoader(false)
                    }
                })
                .catch((error) => {
                    setAllData([]);
                    isLoader(false)
                });

        } else if (selectedFilter === 'refunds') {
            isLoader(true)
            const updatedRequestData = {
                ...requestDataWithSearch,
                value: 'refunded'
            };
            paymentReceived(updatedRequestData)
                .then((response) => {
                    if (response.code === "1") {
                        const dataWithRowCount = response.data.map((row, index) => ({ ...row, id: index + 1 }));
                        setRefundData(dataWithRowCount);
                        isLoader(false)
                    } else {
                        setRefundData([]);
                        isLoader(false)
                    }
                })
                .catch((error) => {
                    setRefundData([]);
                    isLoader(false)
                });

        } else if (selectedFilter === 'payments_made') {

            isLoader(true)
            PaymentMadetoTeachers(requestDataWithSearch)
                .then((response) => {
                    if (response.code === "1") {
                        const dataWithRowCount = response.data.map((row, index) => ({ ...row, id: index + 1 }));
                        setPaymentMadeData(dataWithRowCount);
                        isLoader(false)
                    } else {
                        setPaymentMadeData([]);
                        isLoader(false)
                    }
                })
                .catch((error) => {
                    setPaymentMadeData([]);
                    isLoader(false)
                });

        } else if (selectedFilter === 'payments_received') {

             isLoader(true)
            paymentReceived(requestDataWithSearch)
                .then((response) => {
                console.log('response :', response);
                    if (response.code === "1") {
                        const dataWithRowCount = response.data.map((row, index) => ({ ...row, id: index + 1 }));
                        setPaymentReceivedData(dataWithRowCount);
                         isLoader(false)
                    } else {
                        setPaymentReceivedData([]);
                         isLoader(false)
                    }
                })
                .catch((error) => {
                    setPaymentReceivedData([]);
                     isLoader(false)
                });

        } else if (selectedFilter === 'credits') {

             isLoader(true)
            refferalCredits(requestDataWithSearch)
                .then((response) => {
                    if (response.code === "1") {
                        const dataWithRowCount = response.data.map((row, index) => ({ ...row, id: index + 1 }));
                        setCreditsData(dataWithRowCount);
                         isLoader(false)
                    } else {
                        setCreditsData([]);
                         isLoader(false)
                    }
                })
                .catch((error) => {
                    setCreditsData([]);
                     isLoader(false)
                });

        } else if (selectedFilter === 'aID') {

        } else if (selectedFilter === 'unaID') {

        } else if (selectedFilter === 'payment_hold') {

        } else if (selectedFilter === 'payment_under_review') {

        } else if (selectedFilter === 'payment_denied') {

        } else {
            setPaymentMadeData([]);
            setRefundData([]);
            setPaymentReceivedData([]);
            setAllData([]);
        }

    };

    const handlePeriodChange = (selectedOption) => {

        setSelectedOption(selectedOption);
        setSelectedPeriod(selectedOption.value);

        // Update start and end dates immediately
        const currentDate = new Date();
        if (selectedOption.value === 'daily') {
            setStartDate(currentDate.toISOString().split('T')[0]);
            setEndDate(currentDate.toISOString().split('T')[0]);
        } else if (selectedOption.value === 'weekly') {
            const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
            setStartDate(sevenDaysAgo.toISOString().split('T')[0]);
            setEndDate(currentDate.toISOString().split('T')[0]);
        }
    };

    const handleStartMonthChange = (value) => {
        setStartMonth(value);
    };

    const handleEndMonthChange = (value) => {
        setEndMonth(value);
    };

    const getYearOptions = (minYear) => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = minYear; year <= currentYear; year++) {
            years.push(year);
        }
        return years;
    };

    const renderYearContent = (year) => {
        const tooltipText = `Tooltip for year: ${year}`;
        return <span title={tooltipText}>{year}</span>;
    };

    const getCurrentMonthYear = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
        return `${year}-${month}`;
    };

    const currentMonthYear = getCurrentMonthYear();

    const renderCustomHeader = ({
        date,
        changeYear,
        decreaseYear,
        increaseYear,
        prevYearButtonDisabled,
        nextYearButtonDisabled
    }) => (
        <div>
            <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                {'<'}
            </button>
            <span>{date.getFullYear()}</span>
            <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
                {'>'}
            </button>
        </div>
    )

    const handleFilterChange = (selectedOption) => {
        setSelectedFilterOption(selectedOption);
        fetchEwalletData(selectedOption.value)
    }

    const downloadPdf = async (payload) => {
        try {
            const response = await invoice_pdf(payload);

            if (response.code === "1" && response.data) {
                const pdfUrl = response.data;

                const pdfResponse = await fetch(pdfUrl);
                const pdfBlob = await pdfResponse.blob();

                const randomName = `invoice_${Date.now()}.pdf`;

                const pdfFile = new File([pdfBlob], randomName, { type: 'application/pdf' });

                const pdfDataUrl = URL.createObjectURL(pdfFile);

                const downloadLink = document.createElement('a');
                downloadLink.href = pdfDataUrl;
                downloadLink.download = randomName;

                downloadLink.click();
            } else {
                console.error('Invalid response or PDF URL not found.');
            }
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    }

    const paymentMadeColumns = [
        {
            name: 'No',
            selector: row => row.id,
            sortable: false,
            width: "70px"
        },
        {
            name: 'From',
            cell: () => 'StudentScholar',
            sortable: true,
            width: '150px'
        },
        {
            name: 'To Code',
            selector: row => row.teachercode,
            sortable: true,
            width: "100px",
            cell: row => row.teachercode == null ? "0000" : row.teachercode
        },
        {
            name: 'To Name',
            selector: row => row.teacher_name,
            sortable: true,
            width: "150px",
            cell: row => (
                row.teacher_category == 'teacher' ? (
                    <span
                        onClick={() => navigate('/teacher_details', { state: { teacherId: row.teacher_id } })}
                        style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                    >
                        {row.teacher_name}
                    </span>
                ) : row.teacher_category == 'organization' ? (
                    <span
                        onClick={() => navigate('/org_details', { state: { userId: row.teacher_id } })}
                        style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                    >
                        {row.teacher_name}
                    </span>
                ) : (
                    <span>{row.teacher_name}</span>
                )
            )
        },
        {
            name: 'Role',
            selector: row => row.teacher_category,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Program Name',
            selector: row => row.activity_title ? row.activity_title : '-',
            sortable: true,
            width: "180px"
        },
        {
            name: (
                <>
                    Program<br />Type
                </>
            ),
            selector: row => row.activity_type ? row.activity_type : "-",
            sortable: true,
            width: "100px"
        },
        {
            name: 'PlatformType',
            selector: row => row.platform_type ? row.platform_type : "-",
            sortable: true,
            width: "130px"
        },
        {
            name: 'Duration',
            selector: row => row.duration ? row.duration : "-",
            sortable: true,
            width: "155px"
        },
        {
            name: 'Amount',
            selector: row => `$ ${row.teacher_amount}`,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Transaction Id',
            selector: row => row.transaction_id,
            sortable: true,
            width: "250px"
        },
        {
            name: (
                <>
                    Payment<br />Status
                </>
            ),
            selector: row => (
                <button className="bg-success border-0 text-light fw-500 px-1">
                    {row.payment_status}
                </button>
            ),
            sortable: true,
            width: "100px"
        },
        {
            name: (
                <>
                    Payment<br />Date
                </>
            ),
            // selector: row => {
            //     const date = new Date(row.created_at);
            //     const day = date.getDate();
            //     const month = date.getMonth() + 1;
            //     const formattedMonth = month < 10 ? `0${month}` : month;
            //     const formattedDay = day < 10 ? `0${day}` : day;
            //     const year = date.getFullYear();
            //     return `${formattedDay}-${formattedMonth}-${year}`;
            // },
            selector: row => row.enrolled_at,
            sortable: true,
            width: "150px"
        },
    ]

    const refundColumns = [
        {
            name: 'No',
            selector: row => row.id,
            sortable: false,
            width: "60px"
        },
        {
            name: 'From Code',
            selector: row => row.teachercode,
            sortable: false,
            width: "100px"
        },
        {
            name: 'Teacher Name',
            selector: row => row.teacher_name,
            sortable: true,
            width: "140px",
            cell: row => {

                if (row.teacher_category == 'teacher') {
                    return (
                        <span
                            onClick={() => navigate('/teacher_details', { state: { teacherId: row.teacher_id } })}
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                        >
                            {row.teacher_name}
                        </span>
                    )
                } else if (row.teacher_category == 'organization') {
                    return (
                        <span
                            onClick={() => navigate('/org_details', { state: { userId: row.teacher_id } })}
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                        >
                            {row.teacher_name}
                        </span>
                    )
                } else {
                    return (
                        <span
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                        >
                            {row.teacher_name}
                        </span>
                    );
                }
            }
        },
        {
            name: 'To Code',
            selector: row => row.usercode,
            sortable: false,
            width: "100px"
        },
        {
            name: 'User Name',
            selector: row => row.username,
            sortable: true,
            width: "140px",
            cell: row => (
                <span
                    onClick={() => navigate('/user_details', { state: { id: row.user_id } })}
                    style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                >
                    {row.username}
                </span>
            )
        },
        {
            name: 'Amount',
            selector: row => `$ ${row.amount}`,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Transaction Id',
            selector: row => row.refund_id,
            sortable: true,
            width: "260px"
        },
        {
            name: 'Program Name',
            selector: row => row.activity_title,
            sortable: true,
            width: "170px"
        },
        {
            name: 'ProgramType',
            selector: row => row.activity_type,
            sortable: true,
            width: "130px"
        },
        {
            name: 'PlatformType',
            selector: row => row.platform_type,
            sortable: true,
            width: "130px"
        },
        {
            name: 'Duration',
            selector: row => row.duration,
            sortable: true,
            width: "160px"
        },
        {
            name: 'RefundStatus',
            selector: row => (
                <button className="bg-success border-0 text-light px-1 fw-500">
                    {row.payment_status}
                </button>
            ),
            sortable: true,
            width: "130px"
        },
        {
            name: 'RefundDate',
            selector: row => {
                const date = new Date(row.refund_date);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "120px"
        },
    ]

    const paymentReceivedColumns = [
        {
            name: 'No',
            selector: row => row.id,
            sortable: false,
            width: "60px"
        },
        {
            name: (
                <>
                    Payment<br />Date
                </>
            ),
            // selector: row => {
            //     const date = new Date(row.enrolled_at);
            //     const day = date.getDate();
            //     const month = date.getMonth() + 1;
            //     const formattedMonth = month < 10 ? `0${month}` : month;
            //     const formattedDay = day < 10 ? `0${day}` : day;
            //     const year = date.getFullYear();
            //     return `${formattedDay}-${formattedMonth}-${year}`;
            // },
            selector: row => row.enrolled_at,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Transaction Id',
            selector: row => row.transaction_id,
            sortable: true,
            width: "260px"
        },
        {
            name: 'FromCode',
            selector: row => row.usercode ? row.usercode : "-",
            sortable: true,
            width: "120px"
        },
        {
            name: 'From Name',
            selector: row => row.from_account,
            sortable: true,
            width: "120px",
            cell: row => (
                <span
                    onClick={() => navigate('/user_details', { state: { id: row.user_id } })}
                    style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                >
                    {row.from_account}
                </span>
            )
        },
        {
            name: 'To Code',
            selector: row => row.teachercode ? row.teachercode : "-",
            sortable: true,
            width: "100px"
        },
        {
            name: 'To Name',
            selector: row => row.to_account,
            sortable: true,
            width: "110px",
            cell: row => (
                row.teacher_category == 'teacher' ? (
                    <span
                        onClick={() => navigate('/teacher_details', { state: { teacherId: row.teacher_id } })}
                        style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                    >
                        {row.to_account}
                    </span>
                ) : row.teacher_category == 'organization' ? (
                    <span
                        onClick={() => navigate('/org_details', { state: { userId: row.teacher_id } })}
                        style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                    >
                        {row.to_account}
                    </span>
                ) : (
                    <span>{row.to_account}</span>
                )
            )
        },
        {
            name: 'Program Name',
            selector: row => row.activity_title,
            sortable: true,
            width: "160px",
            cell : row => (
                <span 
                style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }} 
                onClick={()=> navigate('/ewallet_group_transactions' , { state : {learner_id: row.user_id ,
                    activity_id: row.activity_id}})}>{row.activity_title}</span>
            )
        },
        {
            name: (
                <>
                    Program<br />Type
                </>
            ),
            selector: row => row.activity_type,
            sortable: true,
            width: "100px"
        },
        {
            name: (
                <>
                    Platform<br />Type
                </>
            ),
            selector: row => row.platform_type ? row.platform_type : "Online",
            sortable: true,
            width: "100px"
        },
        {
            name: 'Duration',
            selector: row => row.duration,
            sortable: true,
            width: "155px"
        },
        {
            name: (
                <>
                    Tax<br />Charge
                </>
            ),
            selector: row => row.tax_charge,
            sortable: true,
            width: "80px"
        },
        {
            name: 'Amount',
            selector: row => `$ ${row.total_amount}`,
            sortable: true,
            width: "100px"
        },
        {
            name: (
                <>
                    Payment<br />Status
                </>
            ),
            selector: row => (
                <button className="bg-success border-0 text-light px-1 fw-500"> {row.payment_status}</button>
            ),
            sortable: true,
            width: "140px"
        },
        {
            name: 'Action',
            width: "80px",
            selector: row => row.id,
            center: true,
            cell: (row) => <>
                <button type="button" className="btn" style={{ boxShadow: 'none' }} id={row.id} ><a className="material-icons"
                    onClick={() => {
                        const idToSend = (row.learner_id == null || row.learner_id === "") ? row.user_id : row.learner_id;
                        const payload = {
                            activity_id: row.activity_id.toString(),
                            activity_type: row.activity_type,
                            ...(row.learner_id ? { learnerId: row.learner_id } : { userId: row.user_id })
                        };
                        downloadPdf(payload);
                    }}

                    id={row.id} >  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM19.9206 24.6625H10.0794C9.74786 24.6625 9.42992 24.5308 9.1955 24.2964C8.96108 24.062 8.82938 23.744 8.82938 23.4125C8.82938 23.081 8.96108 22.763 9.1955 22.5286C9.42992 22.2942 9.74786 22.1625 10.0794 22.1625H19.9206C20.2522 22.1625 20.5701 22.2942 20.8045 22.5286C21.0389 22.763 21.1706 23.081 21.1706 23.4125C21.1706 23.744 21.0389 24.062 20.8045 24.2964C20.5701 24.5308 20.2522 24.6625 19.9206 24.6625ZM20.8044 15.26L15.88 20.1875C15.6456 20.4218 15.3277 20.5535 14.9963 20.5535C14.6648 20.5535 14.3469 20.4218 14.1125 20.1875L9.19563 15.2713C8.96075 15.0369 8.8286 14.7188 8.82824 14.387C8.82789 14.0551 8.95937 13.7368 9.19376 13.5019C9.42814 13.267 9.74623 13.1348 10.0781 13.1345C10.4099 13.1341 10.7283 13.2656 10.9631 13.5L13.75 16.2869V5.22813C13.75 4.8966 13.8817 4.57866 14.1161 4.34424C14.3505 4.10982 14.6685 3.97813 15 3.97813C15.3315 3.97813 15.6495 4.10982 15.8839 4.34424C16.1183 4.57866 16.25 4.8966 16.25 5.22813V16.2794L19.0369 13.4925C19.2726 13.2648 19.5884 13.1388 19.9161 13.1417C20.2439 13.1445 20.5574 13.276 20.7892 13.5077C21.0209 13.7395 21.1524 14.053 21.1552 14.3807C21.1581 14.7085 21.0321 15.0242 20.8044 15.26Z" fill="#570861" />
                    </svg></a> </button></>
        }
    ]

    const AllDataColumns = [
        {
            name: 'No',
            selector: row => row.id,
            sortable: false,
            width: "50px"
        },
        {
            name: 'Date',
            selector: row => {
                // let date;
                // if (row.payment_status === "paid") {
                //     date = new Date(row.created_at);
                // } else if (row.payment_status === "succeeded") {
                //     date = new Date(row.enrolled_at);
                // } else if (row.payment_status === "refunded") {
                //     date = new Date(row.enrolled_at);
                // } else {
                //     date = new Date();
                // }

                // const day = date.getDate();
                // const month = date.getMonth() + 1;
                // const formattedMonth = month < 10 ? `0${month}` : month;
                // const formattedDay = day < 10 ? `0${day}` : day;
                // const year = date.getFullYear();
                // return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "100px",
            selector: row => row.enrolled_at,
        },
        {
            name: 'Transaction Id',
            selector: row => row.transaction_id,
            sortable: true,
            width: "250px"
        },
        {
            name: (
                <>
                    From<br />Code
                </>
            ),
            selector: row => {
                if (row.payment_status === "refunded") {
                    return row.teachercode ? row.teachercode : "-";
                } else if (row.payment_status === "succeeded") {
                    return row.usercode ? row.usercode : "-";
                } else if (row.payment_status === "paid") {
                    return "-";
                } else {
                    return row.usercode ? row.usercode : "-";
                }
            },
            sortable: true,
            width: "90px"
        },
        {
            name: 'From Name',
            selector: row => {
                if (row.payment_status === "refunded") {
                    return row.to_account;
                } else if (row.payment_status === "succeeded") {
                    return row.from_account;
                } else if (row.payment_status === "paid") {
                    return "StudentScholar";
                } else {
                    return row.from_account;
                }
            },
            sortable: true,
            width: "120px",
            cell: row => {
                let displayName;

                if (row.payment_status === "refunded") {
                    displayName = row.to_account;
                } else if (row.payment_status === "succeeded") {
                    displayName = row.from_account;
                } else if (row.payment_status === "paid") {
                    displayName = "StudentScholar";
                } else {
                    displayName = row.from_account;
                }

                if (row.payment_status === "succeeded") {
                    return (
                        <span
                            onClick={() => navigate('/user_details', { state: { id: row.user_id } })}
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: "pointer" }}
                        >
                            {displayName}
                        </span>
                    );
                } else if (row.payment_status === "refunded") {
                    if (row.teacher_category == 'teacher') {
                        return (
                            <span
                                onClick={() => navigate('/teacher_details', { state: { teacherId: row.teacher_id } })}
                                style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                            >
                                {displayName}
                            </span>
                        )
                    } else if (row.teacher_category == 'organization') {
                        return (
                            <span
                                onClick={() => navigate('/org_details', { state: { userId: row.teacher_id } })}
                                style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                            >
                                {displayName}
                            </span>
                        )
                    } else {
                        return (
                            <span
                                style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                            >
                                {displayName}
                            </span>
                        );
                    }

                } else {
                    return (
                        <span
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                        >
                            {displayName}
                        </span>
                    );
                }
            }
        },
        {
            name: (
                <>
                    To<br />Code
                </>
            ),
            selector: row => {
                if (row.payment_status === "refunded") {
                    return row.usercode ? row.usercode : "-";
                } else if (row.payment_status === "succeeded") {
                    return row.teachercode ? row.teachercode : "-";
                } else if (row.payment_status === "paid") {
                    return row.teachercode ? row.teachercode : "-";
                } else {
                    return row.teachercode ? row.teachercode : "-";
                }
            },
            sortable: true,
            width: "90px"
        },
        {
            name: 'To Name',
            selector: row => {
                if (row.payment_status === "refunded") {
                    return row.from_account;
                } else if (row.payment_status === "succeeded") {
                    return row.to_account;
                } else if (row.payment_status === "paid" || row.source == "payment_paid_to_teachers") {
                    return row.teacher_name;
                } else {
                    return row.to_account;
                }
            },
            sortable: true,
            width: "110px",
            cell: row => {
                let displayName;

                if (row.payment_status === "refunded") {
                    displayName = row.from_account;
                } else if (row.payment_status === "succeeded") {
                    displayName = row.to_account;
                } else if (row.payment_status == "paid" || row.source == "payment_paid_to_teachers") {
                    displayName = row.teacher_name;
                } else {
                    displayName = row.to_account;
                }

                if (row.payment_status === "refunded") {
                    return (
                        <span
                            onClick={() => navigate('/user_details', { state: { id: row.user_id } })}
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: "pointer" }}
                        >
                            {displayName}
                        </span>
                    );
                } else if (row.payment_status === "succeeded") {
                    if (row.teacher_category == 'teacher') {
                        return (
                            <span
                                onClick={() => navigate('/teacher_details', { state: { teacherId: row.teacher_id } })}
                                style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                            >
                                {displayName}
                            </span>
                        )
                    } else if (row.teacher_category == 'organization') {
                        return (
                            <span
                                onClick={() => navigate('/org_details', { state: { userId: row.teacher_id } })}
                                style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                            >
                                {displayName}
                            </span>
                        )
                    } else {
                        return (
                            <span
                                style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                            >
                                {displayName}
                            </span>
                        );
                    }
                } else if (row.payment_status === "paid") {

                    if (row.teacher_category == 'teacher') {
                        return (
                            <span
                                onClick={() => navigate('/teacher_details', { state: { teacherId: row.teacher_id } })}
                                style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                            >
                                {displayName}
                            </span>
                        )
                    } else if (row.teacher_category == 'organization') {
                        return (
                            <span
                                onClick={() => navigate('/org_details', { state: { userId: row.teacher_id } })}
                                style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                            >
                                {displayName}
                            </span>
                        )
                    } else {
                        return (
                            <span
                                style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                            >
                                {displayName}
                            </span>
                        );
                    }
                } else {
                    return (
                        <span
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                        >
                            {displayName}
                        </span>
                    );
                }
            }
        },
        {
            name: 'Program Name',
            selector: row => row.activity_title ? row.activity_title : '-',
            sortable: true,
            width: "180px",
            cell : row => (
                <span 
                style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }} 
                onClick={()=> navigate('/ewallet_group_transactions' , { state : {learner_id: row.user_id ,
                    activity_id: row.activity_id}})}>{row.activity_title}</span>
            )
        },
        {
            name: 'ProgramType',
            selector: row => row.activity_type ? row.activity_type : "-",
            sortable: true,
            width: "130px"
        },
        {
            name: 'PlatformType',
            selector: row => row.platform_type ? row.platform_type : "Online",
            sortable: true,
            width: "130px"
        },
        {
            name: 'Duration',
            selector: row => row.duration ? row.duration : "-",
            sortable: true,
            width: "155px"
        },
        {
            name: 'Tax charge',
            selector: row => row.tax_charge ? row.tax_charge : "-",
            sortable: true,
            width: "155px"
        },
        {
            name: 'Amount',
            selector: row => `$ ${row.payment_status === "paid" ? row.teacher_amount : row.total_amount}`,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Status',
            selector: row => {
                let buttonClass = "bg-secondary";
                let buttonText = row.payment_status;

                if (row.payment_status === "paid") {
                    buttonClass = "bg-info text-dark";
                } else if (row.payment_status === "succeeded") {
                    buttonClass = "bg-success text-light";
                } else if (row.payment_status === "refunded") {
                    buttonClass = "bg-warning";
                }

                return (
                    <button className={`${buttonClass} border-0 px-1 fw-500`} style={{width: "80px" }}>
                        {buttonText}
                    </button>
                );
            },
            sortable: true,
            width: "115px"
        },
        // {
        //     name: 'Receipt',
        //     width: "80px",
        //     selector: row => row.id,
        //     center: true,
        //     cell: (row) => <>
        //         <button type="button" className="btn" style={{ boxShadow: 'none' }} id={row.id} ><a className="material-icons"
        //             onClick={() => {
        //                 const idToSend = (row.learner_id == null || row.learner_id === "") ? row.user_id : row.learner_id;
        //                 const payload = {
        //                     activity_id: row.activity_id.toString(),
        //                     activity_type: row.activity_type,
        //                     ...(row.learner_id ? { learnerId: row.learner_id } : { userId: row.user_id })
        //                 };
        //                 downloadPdf(payload);
        //             }}

        //             id={row.id} >  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                 <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM19.9206 24.6625H10.0794C9.74786 24.6625 9.42992 24.5308 9.1955 24.2964C8.96108 24.062 8.82938 23.744 8.82938 23.4125C8.82938 23.081 8.96108 22.763 9.1955 22.5286C9.42992 22.2942 9.74786 22.1625 10.0794 22.1625H19.9206C20.2522 22.1625 20.5701 22.2942 20.8045 22.5286C21.0389 22.763 21.1706 23.081 21.1706 23.4125C21.1706 23.744 21.0389 24.062 20.8045 24.2964C20.5701 24.5308 20.2522 24.6625 19.9206 24.6625ZM20.8044 15.26L15.88 20.1875C15.6456 20.4218 15.3277 20.5535 14.9963 20.5535C14.6648 20.5535 14.3469 20.4218 14.1125 20.1875L9.19563 15.2713C8.96075 15.0369 8.8286 14.7188 8.82824 14.387C8.82789 14.0551 8.95937 13.7368 9.19376 13.5019C9.42814 13.267 9.74623 13.1348 10.0781 13.1345C10.4099 13.1341 10.7283 13.2656 10.9631 13.5L13.75 16.2869V5.22813C13.75 4.8966 13.8817 4.57866 14.1161 4.34424C14.3505 4.10982 14.6685 3.97813 15 3.97813C15.3315 3.97813 15.6495 4.10982 15.8839 4.34424C16.1183 4.57866 16.25 4.8966 16.25 5.22813V16.2794L19.0369 13.4925C19.2726 13.2648 19.5884 13.1388 19.9161 13.1417C20.2439 13.1445 20.5574 13.276 20.7892 13.5077C21.0209 13.7395 21.1524 14.053 21.1552 14.3807C21.1581 14.7085 21.0321 15.0242 20.8044 15.26Z" fill="#570861" />
        //             </svg></a> </button></>
        // }
    ]

    const creditsColumns = [
        {
            name: 'No',
            selector: row => row.id,
            sortable: false,
            width: "300px"
        },
        {
            name: 'Referer',
            selector: row => row.referer,
            sortable: true,
            width: "400px",
            cell: row => {
                if (row.referer_category === 'below_18' ||
                    row.referer_category === 'above_18' ||
                    row.referer_category === 'after_school') {
                    return (
                        <span
                            onClick={() => navigate('/user_details', { state: { id: row.referer_id } })}
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                        >
                            {row.referer}
                        </span>
                    );
                } else if (row.referer_category == 'teacher') {
                    return (
                        <span
                            onClick={() => navigate('/teacher_details', { state: { teacherId: row.referer_id } })}
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                        >
                            {row.referer}
                        </span>
                    )
                } else if (row.referer_category == 'organization') {
                    return (
                        <span
                            onClick={() => navigate('/org_details', { state: { userId: row.referer_id } })}
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                        >
                            {row.referer}
                        </span>
                    )
                } else if (row.referer_category == 'org_teacher') {
                    return (
                        <span
                            onClick={() => navigate('/org_teacher_details', { state: { userID: row.referer_id } })}
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                        >
                            {row.referer}
                        </span>
                    )
                } else {
                    return (
                        <span>{row.referer}</span>
                    )
                }
            }
        },
        {
            name: 'Referee',
            selector: row => row.referee,
            sortable: true,
            width: "400px",
            cell: row => {
                if (row.refree_category === 'below_18' ||
                    row.refree_category === 'above_18' ||
                    row.refree_category === 'after_school') {
                    return (
                        <span
                            onClick={() => navigate('/user_details', { state: { id: row.referee_id } })}
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                        >
                            {row.referee}
                        </span>
                    );
                } else if (row.refree_category == 'teacher') {
                    return (
                        <span
                            onClick={() => navigate('/teacher_details', { state: { teacherId: row.referee_id } })}
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                        >
                            {row.referee}
                        </span>
                    )
                } else if (row.refree_category == 'organization') {
                    return (
                        <span
                            onClick={() => navigate('/org_details', { state: { userId: row.referee_id } })}
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                        >
                            {row.referee}
                        </span>
                    )
                } else if (row.refree_category == 'org_teacher') {
                    return (
                        <span
                            onClick={() => navigate('/org_teacher_details', { state: { userID: row.referee_id } })}
                            style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                        >
                            {row.referee}
                        </span>
                    )
                } else {
                    return (
                        <span>{row.referee}</span>
                    )
                }
            }
        },
        {
            name: 'Amount',
            selector: row => `$ ${row.amount}`,
            sortable: true,
            width: "400px"
        },
        {
            name: 'Referral Status',
            selector: row => (
                <button
                    className={`border-0 text-light fw-500 ${row.is_valid === 1 ? 'bg-success' : 'bg-danger'}`}
                    style={{width : "80px"}}
                >
                    {row.is_valid === 1 ? 'Valid' : 'Not Valid'}
                </button>
            ),
            sortable: true,
            width: "150px"
        }
    ]

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <>
            <JqueryLoad />
            {/* <Hdashboard /> */}
            <main>
                {/* <div className="dashboard-app d-lg-flex px-5 mt-1"> */}
                <div className="px-4 mt-2">

                    <header className="dashboard-toolbar d-lg-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </header>

                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>

                    <div className="d-flex justify-content-between title mt-1">
                        <p className="fs-3 fw-500">E Wallet</p>
                        <div>
                            <button onClick={() => navigate("/refund_listing")} className="btn-theme bg-yellow m-2">Refund Requests</button>
                            <button onClick={() => navigate("/ewallet_balance")} className="btn-theme m-2">E Wallet Balance</button>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between">
                        <div className='col-md-2'>
                            <label className="mb-2">Filter :</label>
                            <Select
                                options={[
                                    { value: "all", label: "All Transactions" },
                                    { value: "payments_received", label: "Payments received" },
                                    { value: "payments_made", label: "Payments made" },
                                    { value: "refunds", label: "Refunds" },
                                    { value: "credits", label: "Credits" },
                                    // { value: "unaID", label: "UnaID" },
                                    // { value: "payment_hold", label: "Payment Hold" },
                                    // { value: "payment_under_review", label: "Payment Under Review" },
                                    // { value: "payment_denied", label: "Payment Denied" },
                                ]}
                                className="skip_pdf"
                                id="filter-select"
                                value={selectedFilterOption}
                                onChange={handleFilterChange}
                                styles={{
                                    width: "100%",
                                    maxWidth: "200px",
                                    minHeight: "40px"
                                }}
                                placeholder="Select Filter"
                            />
                        </div>
                        <div className=' d-flex col-md-10 justify-content-end'>
                            <div className=' col-md-2'>
                                <label className="mb-2">Select Time Period :</label>
                                <Select
                                    options={[
                                        { value: "daily", label: "Daily" },
                                        { value: "weekly", label: "Weekly" },
                                        { value: "monthly", label: "Monthly" },
                                        { value: "yearly", label: "Yearly" },
                                    ]}
                                    className="skip_pdf"
                                    id="period-select"
                                    value={selectedOption}
                                    onChange={handlePeriodChange}
                                    styles={{
                                        width: "100%",
                                        maxWidth: "200px",
                                        minHeight: "40px"
                                    }}
                                    placeholder="Select Time Period"
                                />
                            </div>

                            <div className='col-md-5'>

                                {selectedPeriod === 'daily' && (
                                    <div className="d-flex justify-content-end">
                                        <div className='col-md-5 ms-4'>
                                            <label htmlFor="daily-start-date" className='mb-2'>Start Date:</label>
                                            <input
                                                type="date"
                                                name="start_date"
                                                id="daily-start-date"
                                                className="form-control"
                                                required=""
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-md-5 ms-4">
                                            <label htmlFor="daily-end-date" className='mb-2' >End Date:</label>
                                            <input
                                                type="date"
                                                name="end_date"
                                                id="daily-end-date"
                                                className="form-control"
                                                min={startDate}
                                                required=""
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                )}

                                {selectedPeriod === 'weekly' && (
                                    <div className="d-flex justify-content-end">
                                        <div className='col-md-5 ms-4'>
                                            <label htmlFor="weekly-start-date" className='mb-2'>Start Date:</label>
                                            <input
                                                type="date"
                                                name="start_date"
                                                id="weekly-start-date"
                                                className="form-control"
                                                required=""
                                                value={startDate}
                                                onChange={(e) => {
                                                    const selectedStartDate = e.target.value;
                                                    const sevenDaysLater = new Date(new Date(selectedStartDate).getTime() + 7 * 24 * 60 * 60 * 1000);
                                                    const formattedEndDate = sevenDaysLater.toISOString().split('T')[0];
                                                    setStartDate(selectedStartDate);
                                                    setEndDate(formattedEndDate);
                                                }}
                                            />
                                        </div>

                                        <div className='col-md-5 ms-4'>
                                            <label htmlFor="weekly-end-date" className="mb-2">End Date:</label>
                                            <input
                                                type="date"
                                                name="end_date"
                                                id="weekly-end-date"
                                                className="form-control"
                                                required=""
                                                value={endDate}
                                                onChange={(e) => {
                                                    const selectedEndDate = e.target.value;
                                                    const sevenDaysBefore = new Date(new Date(selectedEndDate).getTime() - 7 * 24 * 60 * 60 * 1000);
                                                    const formattedStartDate = sevenDaysBefore.toISOString().split('T')[0];
                                                    if (new Date(formattedStartDate) > new Date(startDate)) {
                                                        setStartDate(formattedStartDate);
                                                    }
                                                    setEndDate(selectedEndDate);
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}

                                {selectedPeriod === 'monthly' && (
                                    <div className="d-flex justify-content-end">
                                        <div className="col-md-5 ms-4">
                                            <label htmlFor="startMonthPicker" className="mb-2">Start Month-Year:</label>
                                            <input
                                                className="form-control"
                                                type="month"
                                                id="startMonthPicker"
                                                value={startMonth}
                                                onChange={(e) => handleStartMonthChange(e.target.value)}
                                                max={currentMonthYear}
                                            />
                                        </div>
                                        <div className="col-md-5 ms-4">
                                            <label htmlFor="endMonthPicker" className="mb-2">End Month-Year:</label>
                                            <input
                                                className="form-control"
                                                type="month"
                                                id="endMonthPicker"
                                                min={startMonth}
                                                value={endMonth}
                                                onChange={(e) => handleEndMonthChange(e.target.value)}
                                                max={currentMonthYear}
                                            />
                                        </div>
                                    </div>
                                )}

                                {selectedPeriod === 'yearly' && (
                                    <div className="d-flex justify-content-end">
                                        <div className='col-md-5 ms-4'>
                                            <label htmlFor="yearPicker" className='mb-2'>Start Year:</label>
                                            <DatePicker
                                                selected={new Date(startYear, 0, 1)}
                                                onChange={(date) => {
                                                    const newStartYear = date.getFullYear();
                                                    setStartYear(newStartYear);
                                                    if (newStartYear > endYear) {
                                                        setEndYear(newStartYear);
                                                    }
                                                }}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                renderCustomHeader={renderCustomHeader}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className='col-md-5 ms-4'>
                                            <label htmlFor="yearPickerEnd" className="mb-2">End Year:</label>
                                            <DatePicker
                                                selected={new Date(endYear, 0, 1)}
                                                onChange={(date) => {
                                                    const newEndYear = date.getFullYear();
                                                    setEndYear(newEndYear);
                                                }}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                renderCustomHeader={renderCustomHeader}
                                                includeDates={getYearOptions(startYear).map(year => new Date(year, 0, 1))}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="mt-2">
                        <CInputGroup>
                            <CFormInput type="text" placeholder="Search" value={search} onChange={(e) => {
                                setSearch(e.target.value);
                            }} />
                            <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                <FontAwesome className="fa-solid fa-search" name="search" />
                            </CButton>
                        </CInputGroup>
                    </div>

                    <div id='download_earnings'>
                        <div className='mt-1'>

                            {selectedFilterOption.value === 'all' && (
                                <>
                                    {allData && allData.length > 0 ? (
                                        <DataTable columns={AllDataColumns} data={allData} keyField="id" pagination customStyles={customStyles} />
                                    ) : (
                                        <p className="mt-5 text-center text-purple fw-500 fs-3">No data Found</p>
                                    )}
                                </>
                            )}

                            {selectedFilterOption.value === 'payments_made' && (
                                <>
                                    {data && data.length > 0 ? (
                                        <DataTable columns={paymentMadeColumns} data={data} pagination customStyles={customStyles} keyField="id"/>
                                    ) : (
                                        <p className="mt-5 text-center text-purple fw-500 fs-3">No data Found</p>
                                    )}
                                </>
                            )}

                            {selectedFilterOption.value === 'refunds' && (
                                <>
                                    {refundData && refundData.length > 0 ? (
                                        <DataTable columns={refundColumns} data={refundData} pagination customStyles={customStyles} keyField="id"/>
                                    ) : (
                                        <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                                    )}
                                </>
                            )}

                            {selectedFilterOption.value === 'payments_received' && (
                                <>
                                    {paymentReceivedData && paymentReceivedData.length > 0 ? (
                                        <DataTable columns={paymentReceivedColumns} data={paymentReceivedData} pagination customStyles={customStyles} keyField="id"/>
                                    ) : (
                                        <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                                    )}
                                </>
                            )}

                            {selectedFilterOption.value === 'credits' && (
                                <>
                                    {creditsData && creditsData.length > 0 ? (
                                        <DataTable columns={creditsColumns} data={creditsData} pagination customStyles={customStyles} keyField="id"/>
                                    ) : (
                                        <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
