import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { userDetails } from "../../../api/apiHandler";
import Image from "../../../user.png";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";

export default function UserDetails({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const userID = location.state && location.state.id

    const GetOrgDetails = () => {
        isLoader(true)
        userDetails({ id: userID }).then((resposnse) => {
            if (resposnse.code == 1) {
                setData(resposnse.data[0])
                isLoader(false)
            } else {
                setData([]);
                isLoader(false)
            }
        });
    }

    useEffect(() => {
        GetOrgDetails();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>

            </div>
        </header>

        <section className="main_sec">
            <div className="mt-4 mx-5">
                <div className="">
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>

                        <span className="ms-2">Back</span>
                    </span>
                </div>
                <p className="ms-3 fs-4 fw-500 text-center mt-3 mb-5">
                    {data.category === 'below_18' && 'Parent Details'}
                    {data.category === 'after_school' && 'School and After School Details'}
                    {data.category === 'above_18' && 'Learner Details'}
                </p>

                {isLoader && data.length !== 0 ? (
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col col-lg-6 mb-4 mb-lg-0">
                            <div className="card mb-3 p-3" style={{ borderRadius: ".5rem" }}>
                                <div className="row g-0">
                                    <div className="col-md-4 gradient-custom text-center text-white">
                                        <img src={data.profile_image ? data.profile_image : Image}
                                            alt="Avatar" className="img-fluid mt-5 mb-3" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                                        <p className="fs-5 fw-600 text-dark">{data.full_name}</p>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body p-4">
                                            <h6 className="mb-2">Information</h6>
                                            <hr className="mt-0 mb-4" />
                                            <div className="row pt-1">
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Email</p>
                                                    <p className="text-muted mt-1">{data.email}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Phone</p>
                                                    <p className="text-muted mt-1">{data.country_code + " " + data.phone}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Country</p>
                                                    <p className="text-muted mt-1">{data.country}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Time zone</p>
                                                    <p className="text-muted mt-1">{data.time_zone}</p>
                                                </div>

                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Role</p>
                                                    <p className="text-muted mt-1">{data.role}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Category</p>
                                                    <p className="text-muted mt-1">{data.category == "after_school" ? "School & after School Programs"  : data.category == "below_18" ? "Parent" : "Above 18"}</p>
                                                </div>

                                                {data.about_me &&
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">About Me</p>
                                                        <p className="text-muted mt-1">{data.about_me}</p>
                                                    </div>
                                                }
                                                {data.add_qualification &&
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Qualification</p>
                                                        <p className="text-muted mt-1">{data.add_qualification}</p>
                                                    </div>
                                                }

                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Language</p>
                                                    <p className="text-muted mt-1">{data.language}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Status</p>
                                                    <p className="text-muted mt-1">{data.status}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    null
                )}
            </div>
        </section>
    </>
}

